import { Fragment } from 'react';
import Content from './routes/main-routes';
import './styles/main.scss';
import './styles/tailwind.scss';
import './assets/scss/style.scss';

function App() {
  return (
    <Fragment>
      <Content />
    </Fragment>
  );
}

export default App;
