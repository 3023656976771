import {
    BarLoader, BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader, ClipLoader,
    ClockLoader, DotLoader, FadeLoader, GridLoader, HashLoader, MoonLoader, PacmanLoader,
    PropagateLoader, PuffLoader, PulseLoader, RingLoader, RiseLoader, RotateLoader, ScaleLoader, SyncLoader
} from "react-spinners";

type LoadingType =
    "BarLoader" | "BeatLoader" | "BounceLoader" | "CircleLoader" | "ClimbingBoxLoader" | "ClipLoader" |
    "ClockLoader" | "DotLoader" | "FadeLoader" | "GridLoader" | "HashLoader" | "MoonLoader" | "PacmanLoader" |
    "PropagateLoader" | "PuffLoader" | "PulseLoader" | "RingLoader" | "RiseLoader" | "RotateLoader" | "ScaleLoader" |
    "SyncLoader";

interface LoadingProps {
    color?: string;
    type: LoadingType;
    size?: number;
    height?: number;
    width?: number
    cssOverride?: {};
    speedMultiplier?: number;
}

const Loading = ({
    color = "var(--primary-color)",
    type,
    size,
    height,
    width,
    cssOverride,
    speedMultiplier
}: LoadingProps) => {

    return (
        <>
            {type === "BarLoader" && <BarLoader color={color} height={height} width={width} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "BeatLoader" && <BeatLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "BounceLoader" && <BounceLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "CircleLoader" && <CircleLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "ClimbingBoxLoader" && <ClimbingBoxLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "ClipLoader" && <ClipLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "ClockLoader" && <ClockLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "DotLoader" && <DotLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "FadeLoader" && <FadeLoader color={color} height={height} width={width} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "GridLoader" && <GridLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "HashLoader" && <HashLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "MoonLoader" && <MoonLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "PacmanLoader" && <PacmanLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "PropagateLoader" && <PropagateLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "PuffLoader" && <PuffLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "PulseLoader" && <PulseLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "RingLoader" && <RingLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "RiseLoader" && <RiseLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "RotateLoader" && <RotateLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "ScaleLoader" && <ScaleLoader color={color} height={height} width={width} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
            {type === "SyncLoader" && <SyncLoader color={color} size={size} cssOverride={cssOverride} speedMultiplier={speedMultiplier} />}
        </>
    )
}

export default Loading;