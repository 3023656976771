import { Suspense } from 'react';

import LoadingIcon from '../../../stories/LoadingIcon/LoadingIcon'

export const Loader = (Component) => (props: JSX.IntrinsicAttributes) =>
(
  <Suspense fallback={<LoadingIcon type="BeatLoader" />}>
    <Component {...props} />
  </Suspense>
);
