import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import { Loader } from '../components/shared/Loader/Loader';
import { StoryLoader } from '../components/shared/Loader/StoryLoader';

const JourneyRoutes = Loader(
  lazy(() => import('../apps/JourneyPlanner/routes/journey.routes'))
);

const JourneyDeparturesRoutes = Loader(
  lazy(() => import('../apps/JourneyPlanner/routes/departures.routes'))
);

const Favourites = StoryLoader(
  lazy(() => import('../apps/JourneyPlannerV2/routes/favourites.routes'))
)

const Scheduled = StoryLoader(
  lazy(() => import('../apps/JourneyPlannerV2/routes/scheduled.routes'))
)

const LiveDepartures = StoryLoader(
  lazy(() => import('../apps/LiveDepartures/routes/liveDepartures.routes'))
)

const JourneyFaultReportingRoutes = Loader(
  lazy(() => import('../apps/JourneyPlanner/routes/fault-reporting.routes'))
);

const JourneyLocationRoutes = Loader(
  lazy(() => import('../apps/JourneyPlanner/routes/w3w-location.routes'))
);
const FerryRoutes = Loader(
  lazy(() => import('../apps/JourneyPlanner/routes/ferry.routes'))
);

// Campaign App Routes
const CampaignAppRoutes = Loader(lazy(() => import('../apps/campaign/routes')));

// Buddy System App Routes
const BuddySystemRoutes = Loader(
  lazy(() => import('../apps/BuddySystem/routes'))
);

const OnboardingQRAppRoutes = Loader(
  lazy(() => import('../apps/OnboardingQR/routes'))
);

// LandingPage App Routes
const LandingPageRoutes = Loader(
  lazy(() => import('../apps/LandingPage/LandingPage'))
);

const LandingPageV2Routes = Loader(
  lazy(() => import('../apps/LandingPageV2/routes'))
);

const BrochureRoutes = Loader(lazy(() => import('../apps/Brochure/routes')));

// Door To Door Routes
const DoorToDoorRoutes = Loader(
  lazy(() => import('../apps/DoorToDoor/routes/doorToDoor.routes'))
);

const Preferences = Loader(
  lazy(() => import('../apps/DoorToDoor/pages/Preferences/Preferences'))
);

const WhereIsMyTrain = Loader(
  lazy(() => import('../apps/WhereIsMyTrain/routes/whereIsMyTrain.routes'))
);

const SharedJourneyRoutes = Loader(
  lazy(() => import('../apps/JourneyPlannerV2/routes/sharedJourney.routes'))
)

// ----------------------------------------------------------------
export default function Routes() {
  return useRoutes([
    // Journey Planner
    {
      path: '/journeys/*',
      element: <JourneyRoutes />
    },
    {
      path: '/departures/*',
      element: <JourneyDeparturesRoutes />
    },
    {
      path: '/favourites/*',
      element: <Favourites />
    },
    {
      path: '/scheduled/*',
      element: <Scheduled />
    },
    {
      path: '/live-departures/*',
      element: <LiveDepartures />
    },
    {
      path: '/fault-reporting/*',
      element: <JourneyFaultReportingRoutes />
    },
    {
      path: '/location/*',
      element: <JourneyLocationRoutes />
    },
    {
      path: '/ferry/*',
      element: <FerryRoutes />
    },
    // Door To Door
    {
      path: '/door-to-door/*',
      element: <DoorToDoorRoutes />
    },
    // Preferences
    {
      path: '/preferences/*',
      element: <Preferences />
    },
    // Where Is My Train
    {
      path: '/where-is-my-train/*',
      element: <WhereIsMyTrain />
    },
    // Shared Journey
    {
      path: '/shared-journey/*',
      element: <SharedJourneyRoutes />
    },
    // Buddy System
    {
      path: '/buddy-system/*',
      element: <BuddySystemRoutes />
    },
    // Campaign
    {
      path: '/campaign/*',
      element: <CampaignAppRoutes />
    },
    // LandingPage
    {
      path: 'landingPage/*',
      element: <LandingPageRoutes />
    },
    {
      path: '/landingPage/v2/*',
      element: <LandingPageV2Routes />
    },
    {
      path: 'onboarding/*',
      element: <OnboardingQRAppRoutes />
    },
    // Brochure
    {
      path: 'brochure/*',
      element: <BrochureRoutes />
    }
  ]);
}
