import { Suspense } from 'react';

import LoadingIcon from '../../../stories/LoadingIcon/LoadingIcon'

export const StoryLoader = (Component) => (props: JSX.IntrinsicAttributes) =>
(
  <Suspense fallback={
    <div className='spinner'>
      <LoadingIcon type='BeatLoader' />
    </div>
  }>
    <Component {...props} />
  </Suspense>
);